@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Custom CSS */
.diamond-shape {
  transform: rotate(45deg);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* reset capitalization in MUI typography-root */
[class^="MuiTypography-root"]
, [class^="MuiButtonBase"]
, [class$='-MuiButtonBase-root-MuiButton-root']
, [class$='-MuiButtonBase-root-MuiToggleButton-root']
, [class$='-MuiButtonBase-root-MuiTab-root'] {
  text-transform: none !important;
}

.nav-list-item .nav-list-item-button > div > span {
  text-transform: capitalize !important;
}

.capitalized {
  text-transform: capitalize !important;
}

/* darken the color of placeholder text in input fields for hover and selected states in MUI DataGrid */
[class$='-MuiDataGrid-root'] .grid-theme:hover input::placeholder,
[class$='-MuiDataGrid-root'] .grid-theme.Mui-selected input::placeholder {
  color: #6a6559;
  opacity: 0.9;
}

[class$='-MuiDataGrid-root'] .grid-theme:hover input::-webkit-input-placeholder,
[class$='-MuiDataGrid-root'] .grid-theme.Mui-selected input::-webkit-input-placeholder {
  color: #6a6559;
  opacity: 0.9;
}

[class$='-MuiDataGrid-root'] .grid-theme:hover input::-moz-placeholder,
[class$='-MuiDataGrid-root'] .grid-theme.Mui-selected input::-moz-placeholder {
  color: #6a6559;
  opacity: 0.9;
}

[class$='-MuiDataGrid-root'] .grid-theme:hover input::-ms-input-placeholder,
[class$='-MuiDataGrid-root'] .grid-theme.Mui-selected input::-ms-input-placeholder {
  color: #6a6559;
  opacity: 0.9;
}

.share-button svg {
  margin-right: 10px;
}

.addParticipantWrapper {
  display: flex;
  background-color: white;
  align-items: flex-start;
  justify-content:flex-start;
  margin-bottom: 10px;
}